<template>
  <div class="pv-container">
    <div class="pv-contain">
      <div class="pv-item">
        <div class="pv-title">
          <span>
            Procès verbal d'entré pour les appartements
          </span>
        </div>
        <div class="controller">
          <div class="control-item">
            <i class="ik ik-eye"></i>
            <span>voir</span>
          </div>
          <a href="http://localhost:8080/img/bgCity.jpg" class="control-item" download>
            <i class="ik ik-download"></i>
            <span>Télécharger</span>
          </a>
        </div>
      </div>
      <div class="pv-item">
        <div class="pv-title">
          <span>
            Procès verbal d'entré pour les studio
          </span>
        </div>
        <div class="controller">
          <div class="control-item">
            <i class="ik ik-eye"></i>
            <span>voir</span>
          </div>
          <a href="http://localhost:8080/img/bgCity.jpg" class="control-item" download>
            <i class="ik ik-download"></i>
            <span>Télécharger</span>
          </a>
        </div>
      </div>
      <div class="pv-item">
        <div class="pv-title">
          <span>
            Procès verbal d'entré pour les studio meublés
          </span>
        </div>
        <div class="controller">
          <div class="control-item">
            <i class="ik ik-eye"></i>
            <span>voir</span>
          </div>
          <a href="http://localhost:8080/img/bgCity.jpg" class="control-item" download>
            <i class="ik ik-download"></i>
            <span>Télécharger</span>
          </a>
        </div>
      </div>
      <div class="pv-item">
        <div class="pv-title">
          <span>
            Procès verbal d'entré pour les chambres
          </span>
        </div>
        <div class="controller">
          <div class="control-item">
            <i class="ik ik-eye"></i>
            <span>voir</span>
          </div>
          <a href="http://localhost:8080/img/bgCity.jpg" class="control-item" download>
            <i class="ik ik-download"></i>
            <span>Télécharger</span>
          </a>
        </div>
      </div>
      <div class="pv-item">
        <div class="pv-title">
          <span>
            Procès verbal d'entré pour les magasins
          </span>
        </div>
        <div class="controller">
          <div class="control-item">
            <i class="ik ik-eye"></i>
            <span>voir</span>
          </div>
          <a href="http://localhost:8080/img/bgCity.jpg" class="control-item" download>
            <i class="ik ik-download"></i>
            <span>Télécharger</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
  .pv-container {
    height: auto;
    width: 100%;
  }
  .pv-container .pv-contain{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 15px;
  }
  .pv-contain .pv-item{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 9.2rem;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 10px 18px rgba(0, 0, 0, 0.08);
    user-select: none;
    transition: 0.3s ease all;
  }
  .pv-contain .pv-item:hover{
    transform: translateY(-8px);
    box-shadow: 0 10px 18px rgba(0, 0, 0, 0.13);
  }
  .pv-item .pv-title{
    margin-bottom: 20px;
    padding: 0 10px;
    font-size: 1rem;
    font-weight: bold;
    /* text-align: center; */
  }
  .pv-item .controller{
    /* padding: 0 10px; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 100%;
    border-top: 1px #eee solid;
    border-radius: 0 0 8px 8px;
  }
  /* .controller .barre{
    border: 1px solid #eee;
    margin: 0 20px;
    width: 1px;
    height: 100%;
  } */
  .controller .control-item{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .95rem;
    font-weight: 600;
    transition: 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) all;
  }
  .controller .control-item:nth-child(1){
    width: 40%;
    height: 100%;
    border-right: 1px solid #eee;
    border-radius: 0 0 0 8px;
  }
  .controller .control-item:nth-child(2){
    width: 60%;
    height: 100%;
    border-radius: 0 0 8px 0;
  }
  .control-item i{
    font-size: 1rem;
    margin-right: 5px;
  }
  .controller .control-item:nth-child(1):hover{
    background: #191c22;
    border-right: 1px solid #191c22;
    color: white;
  }
  .controller .control-item:nth-child(2):hover{
    background: #F5365C;
    color: white;
  }
</style>